import {HttpService} from "../../../../core/service/http.service";
import {api} from "../../../../config/api";
import * as dateHelper from "../../../../core/helpers/date.helper";
import * as firebase from "firebase";
import {Message} from "../../../../core/models/Message";
import moment from "moment";

export const GAMES_SERVER_ERROR_RESPONSE = 'CREATE_GAME_ERROR_RESPONSE';
export const CREATE_GAME_DETAILS = 'CREATE_GAME_DETAILS';
export const CLEAR_GAME_DETAILS = 'CLEAR_GAME_DETAILS';
export const CLEAR_GAME_PLAYERS = 'CLEAR_GAME_PLAYERS';
export const CLEAR_GAME_LOGS = 'CLEAR_GAME_LOGS';
export const PAGINATION_STARTED = 'PAGINATION_STARTED';
export const PAGINATION_RESULT = 'PAGINATION_RESULT';
export const CHANGE_SEARCH_GAME_ATTRIBUTES = 'CHANGE_SEARCH_GAME_ATTRIBUTES';
export const PAGINATE_GAMES_ERROR_RESPONSE = 'PAGINATE_GAMES_ERROR_RESPONSE';
export const CLEAR_GAME_FORM_DATA = 'CLEAR_GAME_FORM_DATA';
export const CLEAR_GAME_SEARCH_ATTRIBUTES = 'CLEAR_GAME_SEARCH_ATTRIBUTES';
export const GET_GAME = 'GET GAME';
export const SET_GAME = 'SET_GAME';
export const GET_SCORING = 'GET_SCORING';
export const SET_SCORING = 'SET_SCORING';
export const GET_GAME_CHAT = 'GET GAME CHAT';
export const EDIT_GAME = 'EDIT_GAME';
export const GET_SUGGESTIONS = 'GET_SUGGESTIONS';
export const RESET_SUGGESTIONS = 'RESET_SUGGESTIONS';
export const NOTIFY_SUGGESTIONS = 'NOTIFY_SUGGESTIONS';
export const NOTIFY_SUGGESTIONS_SERVER_ERROR = 'NOTIFY_SUGGESTIONS_SERVER_ERROR';
export const GAME_REMOVE_PAYOUT  = 'GAME_REMOVE_PAYOUT';

export const PAYMENT_SERVER_ERROR_RESPONSE = 'PAYMENT_SERVER_ERROR_RESPONSE';
export const PAYMENT_CREATE_BILL_SUCCESSFUL = 'PAYMENT_CREATE_BILL_SUCCESSFUL';

export const GET_BILLS_STARTED = 'GET_BILLS_STARTED';
export const GET_BILLS_SUCCESSFULLY = 'GET_BILLS_SUCCESSFULLY';
export const BILLS_SERVER_ERROR_RESPONSE = 'BILLS_SERVER_ERROR_RESPONSE';
export const CLEAR_BILLS_TABLE = 'CLEAR_BILLS_TABLE';
export const CREATE_GAMES_CSV_STARTED = 'CREATE_GAMES_CSV_STARTED';
export const STOP_LOADING = 'STOP_LOADING';

export const GET_PAYOUT_BILL_STARTED = 'GET_PAYOUT_BILL_STARTED';
export const GET_PAYOUT_BILL_SUCCESSFULLY = 'GET_PAYOUT_BILL_SUCCESSFULLY';
export const PAYOUT_BILL_SERVER_ERROR_RESPONSE = 'PAYOUT_BILL_SERVER_ERROR_RESPONSE';

export const GET_GAME_USERS_STARTED = 'GET_GAME_USERS_STARTED';
export const GET_GAME_USERS_SUCCESSFULLY = 'GET_GAME_USERS_SUCCESSFULLY';
export const GAME_USERS_SERVER_ERROR_RESPONSE = 'GAME_USERS_SERVER_ERROR_RESPONSE';


export const GET_GAME_LOGS_SUCCESSFULLY = 'GET_GAME_LOGS_SUCCESSFULLY';
export const GAME_LOGS_SERVER_ERROR_RESPONSE = 'GAME_LOGS_SERVER_ERROR_RESPONSE';

export const CHANGE_ACCEPT_PAYMENT = 'CHANGE ACCEPT PAYMENT';

export const GET_GAME_TIMELINE_LOGS_SUCCESSFULLY = "GET_GAME_TIMELINE_LOGS_SUCCESSFULLY";

export function getCreateGameDetails() {
  return (dispatch) => {
    HttpService.prepareGetRequest(api.CREATE_GAME_DETAILS).execute(response => {
      dispatch({
        type: CREATE_GAME_DETAILS,
        payload: {
          createGameDetails: response['data']
        }
      });
    });
  };
}

export function getSuggestions(gameId, courseId, stateId) {
  return (dispatch) => {
    const url = `${api.GAMES}/${gameId}/looklike?courseId=${courseId}&stateId=${stateId}`;
    HttpService.prepareGetRequest(url)
      .execute(response => {
        dispatch({
          type: GET_SUGGESTIONS,
          payload: {
            gameSuggestions: {
              data: response['data'],
              isLoading: false
            }
          }
        })
      });
  }
}

export function notifyUsers(body, successCb = null, failureCb = null) {
  return (dispatch) => {
    const url = `${api.GAMES}/${body.gameId}/looklike`;
    HttpService.preparePostRequest(url, body)
      .execute(() => {
        dispatch({
          type: NOTIFY_SUGGESTIONS,
          payload: {}
        });
        if (successCb) {
          successCb();
        }
      }, (error) => {
        dispatch({
          type: NOTIFY_SUGGESTIONS_SERVER_ERROR,
          payload: {
            serverErrors: error['response']['data']['data']
          }
        });
        if (failureCb) {
          failureCb();
        }
      });
  }
}

export function resetSuggestions() {
  return (dispatch) => {
    dispatch({
      type: RESET_SUGGESTIONS,
      payload: {}
    })
  }
}

export function  removePayout(gameId) {
  return (dispatch) => {
    const url = api.GAME_REMOVE_PAYOUT.replace('{gameId}',gameId);
    HttpService.preparePostRequest(url).execute(
      (response) => {
        dispatch({
          type: GAME_REMOVE_PAYOUT,
          payload:response
        })
      }, (error) => {
        console.log(error);
      }
    );
  };
}

export function updatePayoutAmount(gameId, data,successCb = null, failureCb = null) {
  return (dispatch) => {
    HttpService.preparePostRequest(`${api.GAMES}/${gameId}/update-payout-amount`, data).execute(response => {
        if (successCb) {
          successCb(response);
        }
      },
      error => {
        dispatch({
          type: GAME_REMOVE_PAYOUT,
          payload: {
            serverErrors: error['response']['data']['data']
          }
        });

        if(failureCb) {
          failureCb();
          console.log(error);
        }
      });
  };
}

export function createGame(game, successCb = null, failureCb = null) {
  return (dispatch) => {
    HttpService.preparePostRequest(api.GAMES, game).execute(
      (response) => {
        if (successCb) {
          successCb(response);
        }
      },
      error => {
        dispatch({
          type: GAMES_SERVER_ERROR_RESPONSE,
          payload: {
            serverErrors: error['response']['data']['data']
          }
        });
        if (failureCb) {
          failureCb();
        }
      });
  };
}

export function deleteGame(id, cb) {
  const url = api.GAMES_DELETE ;
  return dispatch => HttpService.prepareDeleteRequest(url,{ids:[id]})
    .execute(() => cb())
}

export function restoreGame(id, cb) {
  return dispatch => HttpService.prepareGetRequest(api.GAMES + '/' + id + '/restore')
    .execute(() => cb());
}

export function changeSearchGameAttributes(searchAttributes) {
  return dispatch => {
    dispatch({
      type: CHANGE_SEARCH_GAME_ATTRIBUTES,
      payload: {
        searchAttributes: {...searchAttributes}
      }
    });
  };
}

export function paginateGames(page, perPage, orderBy, orderDir, term, searchAttributes) {
  return (dispatch) => {
    dispatch({
      type: PAGINATION_STARTED,
      payload: {}
    });
    const url = api.GAMES 

 
    
    const params = Object.fromEntries(
      Object.entries({
        ...searchAttributes,
        page,
        perPage,
        orderBy,
        orderDir,
        term,
        course_id: searchAttributes?.course_id
          ? searchAttributes?.course_id.value
          : null,
        game_admin_id: searchAttributes?.game_admin_id
          ? searchAttributes?.game_admin_id.value
          : null,
        fromDate: searchAttributes?.fromDate
          ? dateHelper.format(searchAttributes?.fromDate, "YYYY-MM-DD 00:00:00")
          : "",
        toDate: searchAttributes?.toDate
          ? dateHelper.format(searchAttributes?.toDate, "YYYY-MM-DD 23:59:59")
          : "",
        created_at_fromDate: searchAttributes?.created_at_fromDate
          ? dateHelper.format(
              searchAttributes?.created_at_fromDate,
              "YYYY-MM-DD 00:00:00"
            )
          : "",
        created_at_toDate: searchAttributes?.created_at_toDate
          ? dateHelper.format(
              searchAttributes?.created_at_toDate,
              "YYYY-MM-DD 23:59:59"
            )
          : "",
      }).filter(([key, value]) => {
        if (value !== undefined && value !== null && value !== "") {
          return true;
        }
        return false;
      })
    );



    HttpService.prepareGetRequest(url, params).execute(
      (response) => {
        dispatch({
          type: PAGINATION_RESULT,
          payload: {
            paginationResult: response,
          },
        });
      },
      (error) => {
        dispatch({
          type: PAGINATE_GAMES_ERROR_RESPONSE,
          payload: {
            serverErrors: error["response"]["data"]["data"],
          },
        });
      }
    );
      
  };
}

export function setGame(game) {
  return (dispatch) => {
    dispatch({
      type: SET_GAME,
      payload: {
        game: {...game}
      }
    })
  };
}


export function getGameById(id) {
  
  return dispatch => {
    HttpService.prepareGetRequest(`${api.GAMES}/${id}`).execute(response => {
      dispatch({
        type: GET_GAME,
        payload: {
          game: response['data'],
        }
      })
      dispatch({
        type: CHANGE_ACCEPT_PAYMENT,
        payload: {
          acceptPayment: response['data'].has_prices_list,
        }
      });
    })
  }
}

export function checkAcceptPayment(courseId, successCb = null) {
  return dispatch => {
    HttpService.prepareGetRequest(api.CHECK_ACCEPT_PAYMENT.replace('{id}', courseId)).execute(response => {
      const acceptPayment = response['data']['accept_payment'];
      dispatch({
        type: CHANGE_ACCEPT_PAYMENT,
        payload: {
          acceptPayment: acceptPayment,
        }
      });
      if(successCb) {
        successCb(response)
      }
    })
  }
}

export function getPlayerScoring(userId, gameId) {
  return dispatch => {
    const url = `${api.PLAYERS}/${userId}/games/${gameId}/scoring`;
    HttpService.prepareGetRequest(url).execute(response => {
      dispatch({
        type: SET_SCORING,
        payload: {
          playerScoring: {
            data: response['data'],
            isLoading: false
          }
        }
      })
    })
  }
}

export function clearPlayerScoring() {
  return dispatch => {
    dispatch({
      type: SET_SCORING,
      payload: {
        playerScoring: {
          isLoading: true,
          data: null
        }
      }
    })
  }
}

export function getGameChat(game) {
  
  return dispatch => {
    try {
      game['chat_ref'] = firebase.database().ref(game['chat']['database_url']);
      game['chat_ref_on_change'] = game['chat_ref'].on('value', function (snapshot) {
        game['messages'] = snapshot;
        dispatch({
          type: GET_GAME,
          payload: {
            game: {...game}
          }
        })
      }, function (err) {
        console.log("errr", err);
      });
    } catch (e) {
      console.log("error getting chat", e);
    }

  }
}

export function removeMessage(game, message) {
  return dispatch => {
    try {
      firebase.database().ref(game['chat']['database_url'] + '/' + message.key).remove(function (res) {
        getGameChat(game);
      });
    } catch (e) {
      console.log("error getting chat", e);
    }

  }
}


export function stopListeningToChat(game) {
  return dispatch => {
    try {
      if (game['chat_ref'])
        game['chat_ref'].off();
      if (game['seen_by_ref'] && game['seen_by_ref'].child(game.id))
        game['seen_by_ref'].child(game.id).off();
    } catch (e) {
      console.log("error getting chat", e);
    }

  }
}



export function send(msg, game, user) {
  const databaseUrl = game['chat']['database_url'];
  Message.created_at = moment.utc(moment.now()).format('YYYY-MM-DD HH:mm:ss');
  Message.message = msg;
  Message.type = 'admin';
  Message.user_id = user.id;
  Message.user_name = "Admin - " + user["first_name"];
  Message.user_profile = user.profile_picture;
  return dispatch => {
    firebase.database().ref(databaseUrl).push(Message).then((response) => {
      getGameChat(game);
    });
  }
}

export function editGame(id, game, successCb = null, failureCb = null) {
  return dispatch => {
    HttpService.preparePutRequest(`${api.GAMES}/${id}`, game).execute(response => {
        dispatch({
          type: EDIT_GAME,
          payload: {
            game: response['data']
          }
        });
        if (successCb) {
          successCb(response);
        }
      },
      error => {
        dispatch({
          type: GAMES_SERVER_ERROR_RESPONSE,
          payload: {
            serverErrors: error['response']['data']['data']
          }
        });
        if (failureCb) {
          failureCb();
        }
      });
  }
}

export function clearGameFormDataFromStore() {
  return dispatch => dispatch({
    type: CLEAR_GAME_FORM_DATA
  })
}

export function clearGameDetails() {
  return dispatch => dispatch({
    type: CLEAR_GAME_DETAILS
  });
}

export function clearGameSearchAttributes() {
  return dispatch => dispatch({
    type: CLEAR_GAME_SEARCH_ATTRIBUTES
  })
}


export function quotePlayer(params, successCb = null, failureCb = null) {
  return dispatch => {
    HttpService.preparePostRequest(api.BILLS, params).execute(
      (response) => {
        dispatch({
          type: PAYMENT_CREATE_BILL_SUCCESSFUL,
          payload: {
            game: response['data'],
          }
        });
        if (successCb) {
          successCb(response['data']);
        }
      },
      error => {
        dispatch({
          type: PAYMENT_SERVER_ERROR_RESPONSE,
          payload: {
            serverErrors: error['response']['data']['data']
          }
        });
        if (failureCb) {
          failureCb();
        }
      });
  };
}


export function getGameBills(gameId, page = 1, perPage = 20) {
  return dispatch => {
    dispatch({
      type: GET_BILLS_STARTED
    });

    HttpService.prepareGetRequest(api.GAME_BILLS.replace('{id}', gameId) + '?page=' + page + '&per_page=' + perPage).execute(response => {
        dispatch({
          type: GET_BILLS_SUCCESSFULLY,
          payload: {
            paginationResult: response
          }
        });

      },
      error => dispatch({
        type: BILLS_SERVER_ERROR_RESPONSE,
        payload: {
          serverErrors: error['response']['data']
        }
      })
    );
  }
}

export function getGamePayoutBill(gameId) {
  return dispatch => {
    dispatch({
      type: GET_PAYOUT_BILL_STARTED
    });

    HttpService.prepareGetRequest(api.GAME_PAYOUT_BILL.replace('{id}', gameId)).execute(response => {
        dispatch({
          type: GET_PAYOUT_BILL_SUCCESSFULLY,
          payload: {
            paginationResult: response
          }
        });

      },
      error => dispatch({
        type: PAYOUT_BILL_SERVER_ERROR_RESPONSE,
        payload: {
          serverErrors: error['data']
        }
      })
    );
  }
}


export function createGamesCSV(data, successCb = null, failureCb = null) {
  return (dispatch) => {
    dispatch({
      type: CREATE_GAMES_CSV_STARTED,
      payload: {}
    });
    HttpService.preparePostRequest(api.CREATE_GAMES_CSV, data).execute(
      (response) => {
        if (successCb) {
          dispatch({
            type: STOP_LOADING,
            payload: {}
          });
          successCb(response);
        }
      },
      error => {
        console.log("errr", error);
        dispatch({
          type: GAMES_SERVER_ERROR_RESPONSE,
          payload: {
            serverErrors: error['response']['data']['data']
          }
        });
        if (failureCb) {
          failureCb();
        }
      });
  };
}

export function getGameUsers(gameId) {
  return dispatch => {
    dispatch({
      type: GET_GAME_USERS_STARTED
    });

    HttpService.prepareGetRequest(api.GAME_PLAYERS.replace('{id}', gameId)).execute(response => {
        dispatch({
          type: GET_GAME_USERS_SUCCESSFULLY,
          payload: {
            players: response['data']
          }
        });

      },
      error => dispatch({
        type: GAME_USERS_SERVER_ERROR_RESPONSE,
        payload: {
          serverErrors: error['data']
        }
      })
    );
  }
}

export function getGameLogs(gameId) {
  return dispatch => {


    HttpService.prepareGetRequest(api.GAME_LOGS.replace('{id}', gameId)).execute(response => {
        dispatch({
          type: GET_GAME_LOGS_SUCCESSFULLY,
          payload: {
            logs: response['data']
          }
        });

      },
      error => dispatch({
        type: GAME_LOGS_SERVER_ERROR_RESPONSE,
        payload: {
          serverErrors: error['data']
        }
      })
    );
  }
}

export function clearGamePlayers(){
  return dispatch => dispatch({
    type: CLEAR_GAME_PLAYERS
  });
}
export function clearGameLogs(){
  return dispatch => dispatch({
    type: CLEAR_GAME_LOGS
  });
}



