import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Loadable from 'react-loadable';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css'
import './App.scss';
import { store } from "./shared/store";
import { Provider } from 'react-redux';
import { Auth } from "./modules/authentication/components/auth/auth.component";
import { Authorization } from "./core/components/authorization/authorization.component";
import { ServerErrorHandler } from "./core/components/server-error-handler/server-error-handler.component";
import { ErrorBoundary } from "./core/components/error-boundary/error-boundary.component";
import ReduxToastr from 'react-redux-toastr'
import 'react-table/react-table.css';
import * as firebase from "firebase";
import { config } from "./config/config";
import { Loading as loading } from './core/components/loading/loading.component';

// Containers
const Layout = Loadable({
  loader: () => import('./modules/layouts'),
  loading
});

// Pages
const Login = Loadable({
  loader: () => import('./modules/authentication/components/login/login.component'),
  loading
});

const ResetPassword = Loadable({
  loader: () => import('./modules/authentication/components/reset-password/reset-password.component'),
  loading
});

const Page404 = Loadable({
  loader: () => import('./views/Pages/Page404'),
  loading
});

const Page500 = Loadable({
  loader: () => import('./views/Pages/Page500'),
  loading
});

class App extends Component {

  constructor(props) {
    super(props);
    firebase.initializeApp(config.firebase);
    if (!(JSON.parse(process.env.REACT_APP_DEBUG))) {

      // Analytic init goes here..
    }
  }

  render() {
    return (
      <Provider store={store}>
        <ReduxToastr
          timeOut={3000}
          newestOnTop={false}
          preventDuplicates
          position="bottom-center"
          transitionIn="fadeIn"
          transitionOut="fadeOut"
          closeOnToastrClick />
        <BrowserRouter>
          <ErrorBoundary>
            <ServerErrorHandler>
              <Auth>
                <Authorization>
                  <Switch>
                    <Route exact path="/login" name="Login Page" component={Login} />
                    <Route exact path="/404" name="Page 404" component={Page404} />
                    <Route exact path="/500" name="Page 500" component={Page500} />
                    <Route path="/" name="Home" component={Layout} />
                  </Switch>
                </Authorization>
              </Auth>
            </ServerErrorHandler>
          </ErrorBoundary>
        </BrowserRouter>
      </Provider>
    );
  }
}

export default App;
