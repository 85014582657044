import * as Yup from "yup";

export const table = {
  paginatorConfig: {
    perPage: 20,
    pages: 1
  },
  pageSizeOptions: [20, 50, 75, 100]
};

// export const packageFormSchema = Yup.object().shape({
//   title: Yup.string()
//     .required()
//     .min(3),
// //   course_type_id: Yup.number().required(),
//   state_id: Yup.number().required(),
// });

export const packageFormSchema = Yup.object().shape({
  title: Yup.string()
    .required('Title is required')
    .min(3, 'Title must be at least 3 characters long'),
  country_id: Yup.number().required('Country is required'),
  state_id: Yup.number().required('State is required'),
  price: Yup.number()
    .required('Price is required')
    .positive('Price must be a positive number'),
  days: Yup.number()
    .required('Days are required')
    .min(1, 'At least one day is required'),
  rounds: Yup.number()
    .required('Rounds are required')
    .min(1, 'At least one round is required'),
  short_description: Yup.string()
    .required('Short description is required'),
  description: Yup.string().required('Description is required'),
  itinerary: Yup.string().required('Itinerary is required'),
  includes: Yup.string().nullable(),
  excludes: Yup.string().nullable(),
  // image: Yup.string().required('Image is required'),
  accommodations: Yup.array().of(
    Yup.object().shape({
      accommodation_name: Yup.string().required('Accommodation name is required'),
      accommodation_lat: Yup.number().required('Accommodation latitude is required'),
      accommodation_long: Yup.number().required('Accommodation longitude is required'),
    })
  ),
});

export const managePackagesFilterSchema = Yup.object().shape({
  searchKey: Yup.string()
    .min(1),
});
