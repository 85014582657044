import {api} from "../../../../config/api";
import {HttpService} from "../../../../core/service/http.service";
import {PROFILE_SERVER_ERROR_RESPONSE} from "../../../profile/store/actions/profile.actions";

export const PLAYERS_SERVER_ERROR_RESPONSE = 'PLAYERS_SERVER_ERROR_RESPONSE';
export const PLAYERS_PAGINATION_STARTED = 'PLAYERS_PAGINATION_STARTED';
export const PLAYERS_PAGINATION_RESULT = 'PLAYERS_PAGINATION_RESULT';
export const PLAYERS_CHANGE_SEARCH_ATTRIBUTES = 'PLAYERS_CHANGE_SEARCH_ATTRIBUTES';
export const PLAYERS_PAGINATE_ERROR_RESPONSE = 'PLAYERS_PAGINATE_ERROR_RESPONSE';
export const GET_PLAYER = 'GET_PLAYER';
export const CLEAR_PLAYER_DETAILS = 'CLEAR_PLAYER_DETAILS';
export const GET_PLAYER_GAMES = 'GET_PLAYER_GAMES';
export const GET_PLAYER_GAMES_STARTED = 'GET_PLAYER_GAMES_STARTED';
export const GET_PLAYER_GAMES_ERROR = 'GET_PLAYER_GAMES_ERROR';
export const GET_PLAYER_COURSES = 'GET_PLAYER_COURSES';
export const CLEAR_MANAGE_PLAYERS_DATA = 'CLEAR_MANAGE_PLAYERS_DATA';
export const CLEAR_PLAYER_FORM = 'CLEAR_PLAYER_FORM';
export const IMAGE_UPLOADED = 'IMAGE_UPLOADED';
export const GET_RECEIVED_SCORES_STARTED = 'GET_RECEIVED_SOCRES_STARTED';
export const GET_RECEIVED_SCORES = 'GET_RECEIVED_SOCRES';

export const GET_BILLS_STARTED = 'GET_BILLS_STARTED';
export const GET_BILLS_SUCCESSFULLY = 'GET_BILLS_SUCCESSFULLY';
export const BILLS_SERVER_ERROR_RESPONSE = 'BILLS_SERVER_ERROR_RESPONSE';
export const CLEAR_BILLS_TABLE = 'CLEAR_BILLS_TABLE';

export const GET_REVIEWS_STARTED = "GET_REVIEWS_STARTED";
export const GET_PLAYER_REVIEWS_SUCCESSFULLY = "GET_PLAYER_REVIEWS_SUCCESSFULLY";
export const PLAYERS_REVIEWS_SERVER_ERROR_RESPONSE = "PLAYERS_REVIEWS_SERVER_ERROR_RESPONSE";
export const CLEAR_PLAYER_REVIEWS = "CLEAR_PLAYER_REVIEWS";

export const GET_PLAYER_CREDIT = "GET_PLAYER_CREDIT";
export const GET_PLAYER_CREDIT_STARTED = "GET_PLAYER_CREDIT_STARTED";
export const CLEAR_CREDIT_DETAILS = "CLEAR_CREDIT_DETAILS";
export const PLAYERS_CREDIT_SERVER_ERROR_RESPONSE = "PLAYERS_CREDIT_SERVER_ERROR_RESPONSE";

export const GET_PLAYER_REFERRAL_STARTED = "GET_PLAYER_REFERRAL_STARTED";
export const GET_PLAYER_REFERRAL_HISTORY = "GET_PLAYER_REFERRAL_HISTORY";
export const CLEAR_REFERRAL_DETAILS = "CLEAR_REFERRAL_DETAILS";
export const REFERRAL_HISTORY_SERVER_ERROR_RESPONSE = "REFERRAL_HISTORY_SERVER_ERROR_RESPONSE";



export function getPlayerCredit(playerId , page=1) {
  return (dispatch) => {
    dispatch({
      type: GET_PLAYER_CREDIT_STARTED,
      payload: {}
    });

    const url = `${api.PLAYERS}/${playerId}/credits?page=${page}`;
    HttpService.prepareGetRequest(url).execute(response => {
        dispatch({
          type: GET_PLAYER_CREDIT,
          payload: {
            playerCredit: response
          }
        });
      },
    error => dispatch({
        type: PLAYERS_CREDIT_SERVER_ERROR_RESPONSE,
        payload: {
          serverErrors: error['data']
        }
      })
    );  
  }
}

export function clearCreditDetails() {
  return dispatch => dispatch({
    type: CLEAR_CREDIT_DETAILS
  });
}

export function getPlayerReferral(playerId , page=1, perPage=20) {
  return (dispatch) => {
    dispatch({
      type: GET_PLAYER_REFERRAL_STARTED,
      payload: {}
    });

    const url = `${api.PLAYER_REFERRAL_HISTORY.replace('{id}',playerId)}?page=${page}&per_page=${perPage}`;
    HttpService.prepareGetRequest(url).execute(response => {
        dispatch({
          type: GET_PLAYER_REFERRAL_HISTORY,
          payload: {
            playerReferral: response
          }
        });
      },
    error => dispatch({
        type: REFERRAL_HISTORY_SERVER_ERROR_RESPONSE,
        payload: {
          serverErrors: error['data']
        }
      })
    );  
  }
}

export function clearReferralDetails() {
  return dispatch => dispatch({
    type: CLEAR_REFERRAL_DETAILS
  });
}

export function createPlayerCredit(playerId, credit,successCb = null, failureCb = null) {
  return (dispatch) => {
    HttpService.preparePostRequest(`${api.PLAYERS}/${playerId}/credits`, credit).execute(response => {
        if (successCb) {
          successCb(response);
        }
      },
      error => {
        dispatch({
          type: PLAYERS_CREDIT_SERVER_ERROR_RESPONSE,
          payload: {
            serverErrors: error['response']['data']['data']
          }
        });

        if(failureCb) {
          failureCb();
        }
      });
  };
}

export function getReceivedScores(id, page = 1, per_page = 20) {
  return dispatch => {
    dispatch({
      type: GET_RECEIVED_SCORES_STARTED,
      payload: {}
    });
    const url = `${api.PLAYERS}/${id}/received-scores?page=${page}&per_page=${per_page}`;
    HttpService.prepareGetRequest(url).execute(response => {
        dispatch({
          type: GET_RECEIVED_SCORES,
          payload: {
            paginationResult: response
          }
        });
      },
      error => {
        dispatch({
          type: PLAYERS_SERVER_ERROR_RESPONSE,
          payload: {
            serverErrors: error['response']['data']
          }
        })
      });
  }
}
export function createPlayer(player, successCb = null, failureCb = null) {
  return (dispatch) => {
    HttpService.preparePostRequest(api.PLAYERS, player).execute(response => {
        if (successCb) {
          successCb(response);
        }
      },
      error => {
        dispatch({
          type: PLAYERS_SERVER_ERROR_RESPONSE,
          payload: {
            serverErrors: error['response']['data']['data']
          }
        });
        failureCb();
      });
  };
}

export function clearPlayerForm() {
  return dispatch => dispatch({
    type: CLEAR_PLAYER_FORM
  })
}
export function editPlayer(id, player, successCb = null, failureCb = null) {
  return dispatch => {
    HttpService.preparePutRequest(`${api.PLAYERS}/${id}`, player).execute(response => {
        if (successCb) {
          successCb(response);
        }
      },
      error => {
        dispatch({
          type: PLAYERS_SERVER_ERROR_RESPONSE,
          payload: {
            serverErrors: error['response']['data']['data']
          }
        });
        if (failureCb) {
          failureCb();
        }
      });
  }
}

export function clearManagePlayersData() {
  return dispatch => dispatch({
    type: CLEAR_MANAGE_PLAYERS_DATA
  });
}

export function getPlayerGames(id, category, page = 1, perPage = 20) {
  return (dispatch) => {
    dispatch({
      type: GET_PLAYER_GAMES_STARTED,
      payload: {}
    });
    const url = `${api.PLAYERS}/${id}/games?page=${page}&perPage=${perPage}&category=${category}` ;
    HttpService.prepareGetRequest(url).execute(response => {
        dispatch({
          type: GET_PLAYER_GAMES,
          payload: {
            paginationResult: response
          }
        });
      },
      error => {
        dispatch({
          type: PLAYERS_SERVER_ERROR_RESPONSE,
          payload: {
            serverErrors: error['response']['data']
          }
        })
      });
  }
}


export function getPlayerCourses(id) {
  return dispatch=>{
    HttpService.prepareGetRequest(api.PLAYERS+'/'+id + '/courses').execute(response =>{
      dispatch({
        type:GET_PLAYER_COURSES,
        payload:{
          playerCourses:response['data']
        }
      });
    },
      error =>{
         dispatch({
           type: PLAYERS_SERVER_ERROR_RESPONSE,
           payload: {
             serverErrors: error['response']['data']
           }
         })
      }
    )
  }
}




export function clearPlayerDetails() {
  return dispatch => dispatch({
    type: CLEAR_PLAYER_DETAILS
  });
}

export function getPlayerById(id) {
  return dispatch => {
    const url = `${api.PLAYERS}/${id}`;
    HttpService.prepareGetRequest(url).execute(response => {
        dispatch({
          type: GET_PLAYER,
          payload: {
            player: response['data']
          }
        });
      },
      error => {
        dispatch({
          type: PLAYERS_SERVER_ERROR_RESPONSE,
          payload: {
            serverErrors: error['response']['data']['data']
          }
        })
      });
  }
}

export function changeSearchPlayerAttributes(searchAttributes) {
  return dispatch => {
    dispatch({
      type: PLAYERS_CHANGE_SEARCH_ATTRIBUTES,
      payload: {
        searchAttributes: searchAttributes,
      }
    });
  };
}

export function paginatePlayers(page, perPage, orderBy, orderDir, term, searchAttributes={}) {
  return (dispatch) => {
    dispatch({
      type: PLAYERS_PAGINATION_STARTED,
      payload: {}
    });
    const url = api.PLAYERS 

    if(searchAttributes?.country_id === "null"){
      delete searchAttributes['country_id']
    }

    HttpService.prepareGetRequest(url,{
      ...searchAttributes,
      page: page,
      perPage: perPage,
      orderBy: orderBy,
      orderDir: orderDir,
      term: term,
    }).execute(response => {
        dispatch({
          type: PLAYERS_PAGINATION_RESULT,
          payload: {
            paginationResult: response
          }
        })
      },
      error => dispatch({
        type: PLAYERS_PAGINATE_ERROR_RESPONSE,
        payload: {
          serverErrors: error['response']['data']['data']
        }
      }));
  };
}

export function uploadPlayerImage(imageBase64, player,cb) {
  const isEdit = Object.keys(player).length > 0;
  return dispatch => HttpService.preparePostRequest(api.UPLOAD_PHOTO + ( isEdit ? '?sys_user_id='+player['id'] : ''), imageBase64)
    .execute(
      (response) => {
        player['profile_picture'] = response ?  response["data"]["image_url"] : '';
        cb(player);
      //   dispatch({
      //   type: GET_PLAYER,
      //   payload: {
      //     player: player
      //   }
      // })


      },

      error => dispatch({
        type: PROFILE_SERVER_ERROR_RESPONSE,
        payload: {
          serverErrors: error['response']['data']['data']
        }
      }));
}

export function getPlayerBills(playerId, page = 1 , perPage = 20) {
  return dispatch => {
    dispatch({
      type: GET_BILLS_STARTED
    });

    HttpService.prepareGetRequest(api.PLAYER_BILLS.replace('{id}',playerId) +'?page='+page +'&per_page='+perPage ).execute(response => {
        dispatch({
          type:GET_BILLS_SUCCESSFULLY,
          payload: {
            paginationResult: response
          }
        });

      },
      error => dispatch({
        type: BILLS_SERVER_ERROR_RESPONSE,
        payload: {
          serverErrors: error['response']['data']
        }
      })
    );
  }
}

export function getPlayerReviews(playerId, page = 1 , perPage = 20) {
  return dispatch => {
    dispatch({
      type: GET_REVIEWS_STARTED
    });

    HttpService.prepareGetRequest(api.PLAYER_REVIEWS.replace('{id}',playerId) +'?page='+page +'&per_page='+perPage ).execute(response => {
        dispatch({
          type:GET_PLAYER_REVIEWS_SUCCESSFULLY,
          payload: {
            paginationResult: response
          }
        });

      },
      error => dispatch({
        type: PLAYERS_REVIEWS_SERVER_ERROR_RESPONSE,
        payload: {
          serverErrors: error['response']['data']
        }
      })
    );
  }
}

export function clearPlayerReviews() {
  return dispatch => dispatch({
    type: CLEAR_PLAYER_REVIEWS
  });
}





