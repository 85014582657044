import {
  UPDATE_TEE_TIME,
  GET_TEE_TIME,
  GET_TEE_TIMES_ERROR_RESPONSE,
  GET_TEE_TIMES_SUCCESSFULLY,
  SET_TEE_TIME,
  DELETE_TEE_TIME, CREATE_TEE_TIME
} from "../actions/tee-times.actions";

const initialState = {
  teeTime: null,
  teeTimes: [],
  serverErrors: {},
};

export const teeTimesReducer = function (state = initialState, action) {
  switch (action.type) {
    case GET_TEE_TIMES_SUCCESSFULLY: {
      return {
        ...state,
        teeTimes: action.payload.teeTimes,
        serverErrors: action.payload.serverErrors
      };
    }
    case GET_TEE_TIME: {
      return {
        ...state,
        ...action.payload
      }
    }
    case SET_TEE_TIME: {
      return {
        ...state,
        ...action.payload
      }
    }
    case UPDATE_TEE_TIME: {
      return {
        ...state,
        ...action.payload,
        serverErrors: {}
      }
    }
    case CREATE_TEE_TIME: {
      return {
        ...state,
        ...action.payload,
        serverErrors: {}
      }
    }
    case DELETE_TEE_TIME: {
      return {
        ...state,
        ...action.payload,
        serverErrors: {}
      }
    }
    case GET_TEE_TIMES_ERROR_RESPONSE: {
      return {
        ...state,
        ...action.payload,
        serverErrors: action.payload.serverErrors
      }
    }
    default: {
      return state
    }
  }
};
