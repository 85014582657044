import { HttpService } from "../../../../core/service/http.service";
import { api } from "../../../../config/api";

export const PAGINATION_STARTED = "PAGINATION_STARTED";
export const PAGINATE_PACKAGES_ERROR_RESPONSE =
  "PAGINATE_PACKAGES_ERROR_RESPONSE";
export const PACKAGES_PAGINATION_RESULT = "PACKAGES_PAGINATION_RESULT";
export const CREATE_PACKAGE = "CREATE_PACKAGE";
export const PACKAGE_SERVER_ERROR_RESPONSE = "PACKAGE_SERVER_ERROR_RESPONSE";
export const GET_PACKAGE = "GET_PACKAGE";
export const EDIT_PACKAGE = "EDIT_PACKAGE";
export const CHANGE_SEARCH_PACKAGES_ATTRIBUTES =
  "CHANGE_SEARCH_PACKAGES_ATTRIBUTES";

export function paginatePackages(
  searchAttributes = {
    orderBy: "created_at",
    orderDir: "desc",
  },
  finallyCb = () => {}
) {
  return (dispatch, getState) => {
    const isLoading = getState().packageReducer.table.isLoading;

    console.log("paginatePackages", isLoading);
    // if (isLoading) {
    //   return;
    // }

    dispatch({
      type: PAGINATION_STARTED,
    });
    const url = api.PACKAGES;
    HttpService.prepareGetRequest(url, {
      ...searchAttributes,
    }).execute(
      (response) => {
        dispatch({
          type: PACKAGES_PAGINATION_RESULT,
          payload: {
            paginationResult: response,
          },
        });
      },
      (error) =>
        dispatch({
          type: PAGINATE_PACKAGES_ERROR_RESPONSE,
          payload: {
            serverErrors: error["response"]["data"]["data"],
          },
        }),
      () => {
        finallyCb();
      }
    );
  };
}

export function changeSearchPackagesAttributes(searchAttributes = {}) {
  return (dispatch) => {
    dispatch({
      type: CHANGE_SEARCH_PACKAGES_ATTRIBUTES,
      payload: {
        searchAttributes: { ...searchAttributes },
      },
    });
  };
}

export function createPackage(packages, successCb = null, failureCb = null) {
  return (dispatch) => {
    HttpService.preparePostRequest(api.PACKAGES, packages).execute(
      (response) => {
        dispatch({
          type: CREATE_PACKAGE,
          payload: {
            serverErrors: {},
          },
        });
        if (successCb) {
          successCb(response);
        }
      },
      (error) => {
        dispatch({
          type: PACKAGE_SERVER_ERROR_RESPONSE,
          payload: {
            serverErrors: error["response"]["data"]["data"],
          },
        });
        if (failureCb) {
          failureCb();
        }
      }
    );
  };
}

export function getPackageById(id) {
  return (dispatch) => {
    HttpService.prepareGetRequest(`${api.PACKAGES}/${id}`).execute(
      (response) => {
        dispatch({
          type: GET_PACKAGE,
          payload: {
            packages: response.data,
          },
        });
      }
    );
  };
}

// export function getPackageById(id) {
//   return dispatch => {
//     HttpService.prepareGetRequest(`${api.PACKAGES}/${id}`).execute(response => {
//       dispatch({
//         type: GET_PACKAGE,
//         payload: {
//           packages : response['data']
//         }
//       })
//     })
//   }
// }

export function editPackage(id, packages, successCb = null, failureCb = null) {
  return (dispatch) => {
    HttpService.preparePutRequest(`${api.PACKAGES}/${id}`, packages).execute(
      (response) => {
        dispatch({
          type: EDIT_PACKAGE,
          payload: {
            packages: response["data"],
            serverErrors: {},
          },
        });
        if (successCb) {
          successCb(response);
        }
      },
      (error) => {
        dispatch({
          type: PACKAGE_SERVER_ERROR_RESPONSE,
          payload: {
            serverErrors: error["response"]["data"],
          },
        });
        if (failureCb) {
          failureCb();
        }
      }
    );
  };
}

export function deletePackage(id, successCb = null) {
  return (dispatch) =>
    HttpService.prepareDeleteRequest(api.PACKAGES + "/" + id).execute(() => {
      successCb();
    });
}

export function uploadPackagePhoto(imageBase64, packages, form) {
  return (dispatch) =>
    HttpService.preparePostRequest(
      `${api.UPLOAD_PHOTO_Thumbnail}?model=package&id=${packages.id}`,
      imageBase64
    ).execute(
      (response) => {
        packages.image =
          packages.image === null
            ? response["data"]["image_url"]
            : packages.image + "|" + response["data"]["image_url"];
        form.setFieldValue("image", packages.image);
        console.log(packages.image.split("|"));
      },
      (error) => {
        dispatch({
          type: PACKAGE_SERVER_ERROR_RESPONSE,
          payload: {
            serverErrors: error["response"]["data"],
          },
        });
      }
    );
}
