import * as gamesActions from '../actions/game.actions';
import {table} from "../../config/config";
import * as UserActions from "../../../authentication/store/actions/user.actions";

const initialState = {
  createGameDetails: null,

  game: null,

  playerScoring: {
    data: null,
    isLoading: true
  },


  gameSuggestions: {
    data: {
      golf_course_players: [],
      host_mates: []
    },
    isLoading: true,
    serverErrors: {},
  },

  serverErrors: {},

  searchAttributes: {
    country_id: undefined,
    state_id: undefined,
    course_id: undefined,
    game_type: undefined,
    game_admin_id: undefined,
    fromDate: null,
    toDate: null,
    created_at_fromDate: null,
    created_at_toDate: null,
    searchKey: undefined,
    game_status: undefined,
    accept_payment: 0,
    needing_quotation: 0,
    pending_payment: 0,
    beginner_friendly:0,
    is_web_booking:0,

  },
  table: {
    paginatorConfig: table.paginatorConfig,
    paginationResult: {data: []},
    isLoading: false
  },

  billsTable: {
    paginatorConfig: table.paginatorConfig,
    paginationResult: {data: []},
    isLoading: false
  },
  payoutBillTable: {
    paginationResult: {data: []},
    isLoading: false
  },

  players: [],
  loadingPlayers: false,

  logs: [],
  timelineLogs:[],

  acceptPayment: false,
};

export const gameReducer = function (state = initialState, action) {
  switch (action.type) {
    case gamesActions.GAMES_SERVER_ERROR_RESPONSE: {
      return {
        ...state,
        ...action.payload,
        isLoading: false

      };
    }
    case gamesActions.NOTIFY_SUGGESTIONS_SERVER_ERROR: {
      return {
        ...state,
        gameSuggestions: {
          ...state.gameSuggestions,
          ...action.payload
        }
      };
    }
    case gamesActions.RESET_SUGGESTIONS: {
      return {
        ...state,
        gameSuggestions: {...initialState.gameSuggestions}
      }
    }
    case gamesActions.GET_SUGGESTIONS:
    case gamesActions.CREATE_GAME_DETAILS: {
      return {
        ...state,
        ...action.payload
      };
    }
    case gamesActions.CLEAR_GAME_SEARCH_ATTRIBUTES: {
      return {
        ...state,
        searchAttributes: {
          ...initialState.searchAttributes
        }
      }
    }
    case gamesActions.CHANGE_SEARCH_GAME_ATTRIBUTES: {
      return {
        ...state,
        ...action.payload
      }
    }
    case gamesActions.PAGINATION_RESULT: {
      const {paginationResult} = action.payload;

      return {
        ...state,
        ...action.payload,
        table: {
          paginatorConfig: {
            perPage: +paginationResult['per_page'],
            pages: paginationResult['last_page'],
            index: paginationResult['current_page']
          },
          paginationResult: paginationResult,
          isLoading: false
        }
      }
    }
    case gamesActions.PAGINATION_STARTED: {
      return {
        ...state,
        table: {
          paginatorConfig: state.table.paginatorConfig,
          paginationResult: state.table.paginationResult,
          isLoading: true
        }
      }
    }

    case gamesActions.GAME_REMOVE_PAYOUT: {
      return {
        ...state,
        game: {
          ...state.game,
          payout_to:null
        }
      }
    }
    case gamesActions.PAGINATE_GAMES_ERROR_RESPONSE: {
      return {
        ...state,
        ...action.payload,
        table: {
          ...state.table,
          isLoading: false
        }
      }
    }
    case gamesActions.CLEAR_GAME_FORM_DATA: {
      return {
        ...state,
        game: null
      }
    }
    case gamesActions.CLEAR_GAME_DETAILS: {
      return {
        ...state,
        game: null
      }
    }
    case gamesActions.GET_GAME: {
      return {
        ...state,
        ...action.payload
      }
    }
    case gamesActions.SET_GAME: {
      return {
        ...state,
        ...action.payload
      }
    }
    case gamesActions.GET_SCORING: {
      return {
        ...state,
        ...action.payload
      }
    }
    case gamesActions.SET_SCORING: {
      return {
        ...state,
        ...action.payload
      }
    }
    case gamesActions.EDIT_GAME: {
      return {
        ...state,
        ...action.payload,
        serverErrors: {}
      }
    }
    case UserActions.REMOVE_USER_DATA: {
      return {
        ...initialState
      };
    }

    case gamesActions.PAYMENT_CREATE_BILL_SUCCESSFUL: {
      return {
        ...state,
        ...action.payload
      }
    }

    case gamesActions.GET_BILLS_STARTED: {
      return {
        ...state,
        billsTable: {
          paginatorConfig: state.billsTable.paginatorConfig,
          paginationResult: state.billsTable.paginationResult,

        },
        isLoading: true
      }
    }

    case gamesActions.GET_BILLS_SUCCESSFULLY: {
      const {paginationResult} = action.payload;

      return {
        ...state,
        billsTable: {
          paginatorConfig: {
            perPage: +paginationResult['meta']['per_page'],
            pages: paginationResult['meta']['last_page'],
            index: +paginationResult['meta']['current_page']
          },
          paginationResult: paginationResult,

        },
        isLoading: false
      }
    }


    case gamesActions.GET_PAYOUT_BILL_STARTED: {
      return {
        ...state,
        payoutBillTable: {
          paginationResult: state.payoutBillTable.paginationResult,
        },
        isLoading: true
      }
    }

    case gamesActions.GET_PAYOUT_BILL_SUCCESSFULLY: {
      const {paginationResult} = action.payload;

      return {
        ...state,
        payoutBillTable: {
          paginationResult: paginationResult,
        },
        isLoading: false
      }
    }

    case gamesActions.CREATE_GAMES_CSV_STARTED: {
      return {
        ...state,
        isLoading: true
      }
    }

    case gamesActions.STOP_LOADING: {
      return {
        ...state,
        isLoading: false
      }
    }
    case gamesActions.GET_GAME_USERS_STARTED: {
      return {
        ...state,
        loadingPlayers: true
      }
    }
    case gamesActions.GET_GAME_USERS_SUCCESSFULLY: {
      return {
        ...state,
        ...action.payload,
        loadingPlayers: false

      }
    }

    case gamesActions.GET_GAME_LOGS_SUCCESSFULLY: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case gamesActions.CLEAR_GAME_PLAYERS: {
      return {
        ...state,
        players: []
      }
    }
    case gamesActions.CLEAR_GAME_LOGS: {
      return {
        ...state,
        logs: []
      }
    }

    case gamesActions.CHANGE_ACCEPT_PAYMENT: {
      return {
        ...state,
        ...action.payload
      }
    }

    case gamesActions.GET_GAME_TIMELINE_LOGS_SUCCESSFULLY: {
      return {
        ...state,
        ...action.payload,
      }
    }
    // case gamesActions.CLEAR_GAME_TIMELINE_LOGS: {
    //   return {
    //     ...state,
    //     timelineLogs: []
    //   }
    // }

    default: {
      return state
    }
  }
};
