import { HttpService } from "../../../../core/service/http.service";
import { api } from "../../../../config/api";

export const GET_TEE_TIMES_ERROR_RESPONSE = "GET_TEE_TIMES_ERROR_RESPONSE";
export const TEE_TIMES_SERVER_ERROR_RESPONSE =
  "TEE_TIMES_SERVER_ERROR_RESPONSE";
export const GET_TEE_TIMES_SUCCESSFULLY = "GET_TEE_TIMES_SUCCESSFULLY";
export const GET_TEE_TIMES_STARTED = "GET_TEE_TIMES_STARTED";
export const GET_TEE_TIME = "GET_TEE_TIME";
export const SET_TEE_TIME = "SET_TEE_TIME";
export const UPDATE_TEE_TIME = "UPDATE_TEE_TIME";
export const CREATE_TEE_TIME = "CREATE_TEE_TIME";
export const DELETE_TEE_TIME = "DELETE_TEE_TIME";

export function getTeeTimesByCourseId(
  courseId,
  params = {
    date: "",
  },
  successCb = null,
  failureCb = null,
  finallyCb = null
) {
  return async (dispatch) => {
    dispatch({
      type: GET_TEE_TIMES_STARTED,
      payload: {},
    });
    const url = api.TEE_TIMES.replace("{courseId}", courseId);

    HttpService.prepareGetRequest(url, params).execute(
      (response) => {
        dispatch({
          type: GET_TEE_TIMES_SUCCESSFULLY,
          payload: {
            teeTimes: response.data,
          },
        });
        if (successCb) {
          successCb();
        }
      },
      (error) => {
        dispatch({
          type: GET_TEE_TIMES_ERROR_RESPONSE,
          payload: {
            serverErrors: error,
          },
        });
        if (failureCb) {
          failureCb();
        }
      },
      () => {
        if (finallyCb) {
          finallyCb();
        }
      }
    );
  };
}

export function createBulkTeeTimes(
  courseId,
  payload,
  successCb = null,
  failureCb = null,
  finallyCb = null
) {
  return (dispatch) => {
    const url = api.TEE_TIMES.replace("{courseId}", courseId);

    HttpService.preparePostRequest(url, payload).execute(
      (response) => {
        if (successCb) {
          successCb(response);
        }

        dispatch({
          type: CREATE_TEE_TIME,
          payload: {
            teeTimes: response.data,
          },
        });
      },
      (error) => {
        dispatch({
          type: TEE_TIMES_SERVER_ERROR_RESPONSE,
          payload: {
            serverErrors: error["response"]["data"]["data"],
          },
        });
        if (failureCb) {
          failureCb(error);
        }
      },
      () => {
        if (finallyCb) {
          finallyCb();
        }
      }
    );
  };
}

export function updateTeeTime(
  courseId,
  teeTime,
  successCb = null,
  failureCb = null,
  finallyCb = null
) {
  return (dispatch) => {
    const url =
      api.TEE_TIMES.replace("{courseId}", courseId) + "/" + teeTime.id;
    HttpService.preparePutRequest(url, teeTime).execute(
      (response) => {
        dispatch({
          type: UPDATE_TEE_TIME,
          payload: {
            teeTime: response["data"],
          },
        });
        if (successCb) {
          successCb(response);
        }
      },
      (error) => {
        dispatch({
          type: TEE_TIMES_SERVER_ERROR_RESPONSE,
          payload: {
            serverErrors: error["response"]["data"]["data"],
          },
        });
        if (failureCb) {
          failureCb();
        }
      },
      () => {
        if (finallyCb) {
          finallyCb();
        }
      }
    );
  };
}

export function updateBulkTeeTimes(
  courseId,
  data,
  successCb = null,
  failureCb = null,
  finallyCb = null
) {
  return (dispatch) => {
    const url = api.TEE_TIMES_UPDATE_BULK.replace("{courseId}", courseId);
    HttpService.preparePatchRequest(url, data).execute(
      (response) => {
        dispatch({
          type: UPDATE_TEE_TIME,
          payload: {
            teeTimes: response.data,
          },
        });
        if (successCb) {
          successCb(response);
        }
      },
      (error) => {
        dispatch({
          type: TEE_TIMES_SERVER_ERROR_RESPONSE,
          payload: {
            serverErrors: error["response"]["data"]["data"],
          },
        });
        if (failureCb) {
          failureCb();
        }
      },
      () => {
        if (finallyCb) {
          finallyCb();
        }
      }
    );
  };
}

export function deleteTeeTime(
  courseId,
  id,
  successCb = null,
  failureCb = null,
  finallyCb = null
) {
  const url = api.TEE_TIMES.replace("{courseId}", courseId) + "/" + id;

  return (dispatch) =>
    HttpService.prepareDeleteRequest(url).execute(
      (response) => {
        dispatch({
          type: DELETE_TEE_TIME,
          payload: {
            teeTime: response["data"],
          },
        });

        if (successCb) {
          successCb(response);
        }
      },
      (error) => {
        dispatch({
          type: TEE_TIMES_SERVER_ERROR_RESPONSE,
          payload: {
            serverErrors: error["response"]["data"]["data"],
          },
        });
        if (failureCb) {
          failureCb();
        }
      },
      () => {
        if (finallyCb) {
          finallyCb();
        }
      }
    );
}

export function deleteBulkTeeTimes(
  courseId,
  data,
  successCb = null,
  failureCb = null,
  finallyCb = null
) {
  return (dispatch) => {
    const url = api.TEE_TIMES_DELETE_BULK.replace("{courseId}", courseId);
    HttpService.preparePatchRequest(url, data).execute(
      (response) => {
        dispatch({
          type: UPDATE_TEE_TIME,
          payload: {
            teeTimes: response.data,
          },
        });
        if (successCb) {
          successCb(response);
        }
      },
      (error) => {
        dispatch({
          type: TEE_TIMES_SERVER_ERROR_RESPONSE,
          payload: {
            serverErrors: error["response"]["data"]["data"],
          },
        });
        if (failureCb) {
          failureCb();
        }
      },
      () => {
        if (finallyCb) {
          finallyCb();
        }
      }
    );
  };
}

export function restoreTeeTime(id, cb, courseId) {
  const url = api.TEE_TIMES.replace("{courseId}", courseId);
  return (dispatch) =>
    HttpService.prepareGetRequest(url + "/" + id + "/restore").execute(() =>
      cb()
    );
}
