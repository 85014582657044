const serverUrl = process.env.REACT_APP_SERVER_URL;
export const baseUrl = serverUrl;

export const api = {
  // Auth
  LOGIN: baseUrl + 'log-in',
  LOGOUT: baseUrl + 'log-out',
  MYSELF: baseUrl + 'me',
  CHANGE_PASSWORD: baseUrl + 'change-password',
  RESET_PASSWORD: baseUrl + 'reset-password',

  // Vouchers
  VOUCHERS: baseUrl + 'vouchers',
  VOUCHER_BILLS: baseUrl + 'vouchers/{id}/bills',
  VOUCHER_REQUEST_CODE: baseUrl + 'vouchers/request-voucher-code',
  VOUCHER_LOGS: baseUrl + 'vouchers/{id}/logs',



  // Games
  GAMES: baseUrl + 'games',
  GAMES_DELETE: baseUrl + 'games/destroy',
  CREATE_GAMES_CSV: baseUrl + 'games/csv',
  CREATE_GAME_DETAILS: baseUrl + 'create-game-details',
  GAMES_SEARCH: baseUrl + "games/accept-payment/search",
  GAME_REMOVE_PAYOUT: baseUrl + "games/{gameId}/remove-payout-to",
  // Users
  USERS: baseUrl + 'users',
  SEARCH_SYS_USERS: baseUrl + 'users/search',
  USERS_SEARCH: baseUrl + 'users/business-users/search',
  GET_SYS_USERS: baseUrl + 'users/sys-users/search',
  // Roles
  ROLES: baseUrl + 'roles',
  PERMISSIONS: baseUrl + 'permissions',
  COUNTRIES: baseUrl + 'countries',
  // Players
  PLAYERS: baseUrl + 'players',
  PLAYERS_SEARCH: baseUrl + 'players/search',
  PLAYER_REVIEWS: baseUrl + 'players/{id}/course-ratings',
  // Courses
  COURSES: baseUrl + 'courses',
  STATES_IN_COUNTRY: baseUrl + 'states-in-country',
  COURSES_IN_STATE: baseUrl + 'courses-in-state',
  STATES: baseUrl + 'states',
  COURSES_SEARCH: baseUrl + 'courses/search',
  COURSE_REVIEWS: baseUrl + 'courses/{id}/course-ratings',

  // Packages
  PACKAGES: baseUrl + 'packages',
  //Slugs 
  SLUGS: baseUrl + 'slugs',

  // image upload
  UPLOAD_PHOTO_Thumbnail: baseUrl + 'upload-image',

  //profile
  UPLOAD_PHOTO: baseUrl + 'upload',

  // dashboard
  DASHBOARD: baseUrl + 'reporting',

  // payment
  PAYMENT: baseUrl + 'reporting/payment',
  PAYOUT: baseUrl + 'reporting/payout',
  PAYMENT_TOTAL: baseUrl + 'reporting/payment-status-per-course',
  PAYMENT_VALUES: baseUrl + 'reporting/payment-values',

  GAME_STATUS_PER_COUNTRY: baseUrl + 'reporting/games-per-status-country',
  GAME_TYPE_PER_COUNTRY: baseUrl + 'reporting/games-per-type-country',
  GAME_TYPES: baseUrl + 'game_types',

  REFERRALS_REPORT: baseUrl + 'reporting/referrals',
  PAID_REFERRALS_REPORT: baseUrl + 'reporting/paid-referrals',

  CREATORS_JOINERS: baseUrl + '',
  PLAYERS_PER_COUNTRY: baseUrl + '',

  GAME_ROUNDS_PER_COUNTRY: baseUrl + 'reporting/rounds-per-country',

  // sms
  GET_SMS_LOGS: baseUrl + 'sms-logs',

  // FAQ
  FAQ: baseUrl + 'faqs',
  FAQ_LOGS: baseUrl + 'faqs/{id}/logs',

  //failed-jobs
  FAILED_JOBS: baseUrl + 'failed-jobs',


  // Payment
  BILLS: baseUrl + 'bills',
  COLLECTIONS: baseUrl + 'collections',
  PAYOUT_COLLECTION_CANCEL: baseUrl + 'collections/{collectionId}/cancel-payout',
  CHANNELS: baseUrl + 'bills/payment-channels',
  REFUND_BILL: baseUrl + 'bills/refund-bill',
  GAME_BILLS: baseUrl + 'games/{id}/revenue-report',
  PLAYER_BILLS: baseUrl + 'players/{id}/revenue-report',
  LEADER_BOARD_PERIODS: baseUrl + 'leader-boards',
  LEADER_BOARD_DETAILS: baseUrl + 'leader-board-details',
  GAME_PAYOUT_BILL: baseUrl + 'games/{id}/payout-bill',
  GAME_PLAYERS: baseUrl + 'games/{id}/players',
  GAME_LOGS: baseUrl + 'games/{id}/logs',
  CHECK_ACCEPT_PAYMENT: baseUrl + 'courses/{id}/check-accept-payment',

  // artisan Commands
  ARTISAN_COMMANDS: baseUrl + 'artisan-commands',

  // player referral
  PLAYER_REFERRAL_HISTORY: baseUrl + 'players/{id}/referrals',

  // teetimes
  TEE_TIMES: baseUrl + 'courses/{courseId}/tee-times',
  TEE_TIMES_UPDATE_BULK: baseUrl + 'courses/{courseId}/tee-times/update-bulk',
  TEE_TIMES_DELETE_BULK: baseUrl + 'courses/{courseId}/tee-times/delete-bulk',
  TEE_TIMES_GAMES: baseUrl + 'tee-times/{id}/games',
  TEE_TIME: baseUrl + 'courses/{courseId}/tee-times/{teeTimeId}',

  //TRANSACTIONS: baseUrl + 'bill-transactions'
};
