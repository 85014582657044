import { api } from "../../../../../config/api";
import { HttpService } from "../../../../../core/service/http.service";
import * as dateHelper from "../../../../../core/helpers/date.helper";

export const GET_COLLECTION = "GET_COLLECTION";
export const GET_COLLECTIONS_STARTED = "GET_COLLECTIONS_STARTED";
export const COLLECTIONS_PAGINATION_RESULT = "COLLECTIONS_PAGINATION_RESULT";
export const COLLECTIONS_SERVER_ERROR_RESPONSE =
  "COLLECTIONS_SERVER_ERROR_RESPONSE";
export const CLEAR_COUNTRY_FORM = "CLEAR_COUNTRY_FORM";
export const COUNTRY_DELETED = "Country Deleted Successfully";
export const COUNTRY_RESTORED = "Country Restored Successfully";
export const CHANGE_SEARCH_COLLECTION_ATTRIBUTES =
  "CHANGE_SEARCH_COLLECTION_ATTRIBUTES";
export const OPEN_CONFIRMATION_DIALOG = "OPEN_CONFIRMATION_DIALOG";

export function paginateCollections(
  page,
  perPage,
  orderBy,
  orderDir,
  searchAttributes
) {
  return (dispatch) => {
    dispatch({
      type: GET_COLLECTIONS_STARTED,
    });

    const params = {
      ...searchAttributes,
      page,
      sort_by: orderBy,
      sort_direction: orderDir,
      per_page: perPage,
      game_date_from: searchAttributes?.game_date_from
        ? dateHelper.format(searchAttributes?.game_date_from, "YYYY-MM-DD")
        : "",
      game_date_to: searchAttributes?.game_date_to
        ? dateHelper.format(searchAttributes?.game_date_to, "YYYY-MM-DD")
        : "",
      payout_date_from: searchAttributes?.payout_date_from
        ? dateHelper.format(searchAttributes?.payout_date_from, "YYYY-MM-DD")
        : "",
      payout_date_to: searchAttributes?.payout_date_to
        ? dateHelper.format(searchAttributes?.payout_date_to, "YYYY-MM-DD")
        : "",
    };

    // Remove empty values
    Object.keys(params).forEach((key) => {
      if (
        params[key] === null ||
        typeof params[key] === "undefined" ||
        params[key] === ""
      ) {
        delete params[key];
      }
    });

    const url = api.COLLECTIONS;
    HttpService.prepareGetRequest(url, params).execute(
      (response) => {
        dispatch({
          type: COLLECTIONS_PAGINATION_RESULT,
          payload: {
            paginationResult: response,
          },
        });
      },
      (error) =>
        dispatch({
          type: COLLECTIONS_SERVER_ERROR_RESPONSE,
          payload: {
            serverErrors: error["response"]["data"],
          },
        })
    );
  };
}

export function getCollection(id) {
  return (dispatch) =>
    HttpService.prepareGetRequest(api.COLLECTIONS + "/" + id).execute(
      (response) => {
        dispatch({
          type: GET_COLLECTION,
          payload: {
            collection: response["data"],
          },
        });
      }
    );
}

export function changeSearchCollectionsAttributes(searchAttributes) {
  return (dispatch) => {
    dispatch({
      type: CHANGE_SEARCH_COLLECTION_ATTRIBUTES,
      payload: {
        searchAttributes: { ...searchAttributes },
      },
    });
    //dispatch(paginateCollections(1, perPage, searchAttributes));
  };
}

export function setOpenConfirmationDialog(
  openConfirmationDialog,
  collectionId
) {
  return (dispatch) => {
    dispatch({
      type: OPEN_CONFIRMATION_DIALOG,
      payload: {
        openConfirmationDialog: openConfirmationDialog,
        collectionId: collectionId,
      },
    });
  };
}
